<template>
  <div class="m-portlet m-portlet--full-height m-portlet--mobile porlet-blue-border porlet-products">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <h3 class="m-portlet__head-text">
            Tarjeta de Rebancarización
          </h3>
          <div
            class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-left m-dropdown--align-push"
            m-dropdown-toggle="hover" aria-expanded="true">
          </div>
        </div>
      </div>
    </div>

    <div class="m-portlet__body pt-3">
      <div class="m-widget3">
        <div class="m-widget3__item">
          <div class="m-widget3__header">
            <div class="m-widget3__user-img">
              <img class="m-widget3__img" src="../../../assets/img/tdc.png" alt="">
            </div>
            <div class="m-widget3__info">
              <span v-if="!isActive" class="m-widget3__username">
                Aún no tenemos una tarjeta de crédito aprobada para ti, continua en tu plan de rebancarización y tendrás
                más posibilidades de acceder a este beneficio
              </span>

              <span v-else-if="this.product.delinquentReason && !activePaymentButton" class="m-widget3__username"
                v-html="debtReason">
              </span>

              <span v-else-if="activePaymentButton" class="m-widget3__username" v-html="debtReason">
              </span>

              <span v-else class="m-widget3__username">
                <p class="mb-1">Tienes una tarjeta de crédito pre-aprobada con QNT</p>
                <div class="px-0 px-sm-3">
                  <ul>
                    <li>El cupo otorgado es de ${{ quota }}</li>
                    <li>Recuerda que el cupo otorgado busca acelerar su proceso de Re bancarización</li>
                    <li>Plazo de compras 1 a 36 meses</li>
                    <li>Para uso solamente para compras nacionales</li>
                    <li>No se puede realizar utilización a nivel internacional</li>
                    <li>Cuota de administración de $ 18.750 (Aplican beneficios).</li>
                    <li>Franquicia Visa</li>
                    <li>Nuestra Tarjeta de crédito no cuenta con la opción de avance, motivo por el cual no tendrá clave
                    </li>
                    <li>Validar términos de cuota de manejo</li>
                  </ul>
                </div>
              </span>
            </div>
          </div>

          <div class="m-widget3__body"
            v-if="isActive && !(this.haveDelinquentAccounts || this.product.delinquentReason)">
            <div class="row d-flex justify-center mb-6">
              <div class="col-12 col-md-6">
                <v-btn class="my-1" color="secondary" block @click="openCardTypeModal">
                  Solicitar tarjeta
                </v-btn>
              </div>

              <div class="col-12 col-md-6">
                <v-btn class="my-1" color="secondary" outlined block @click.prevent="rejectCreditCard">
                  NO ME INTERESA
                </v-btn>
              </div>
            </div>
          </div>
          <div class="m-widget3__body" v-else-if="activePaymentButton">
            <div class="row d-flex justify-content-center mb-6">
              <div class="col-12 col-md-6">
                <v-btn class="m-2" color="secondary" block @click.prevent="paymentClick">
                  PAGAR
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CreditCardModal :dialog.sync="dialog" :defaultMobile="defaultMobile" :product="product"
      @update-mobile="updateMobileTC" />

    <ErrorFormAlert :errors="errors" />

    <!-- Nueva modal para seleccionar el tipo de tarjeta -->
     <div v-if="cardTypeDialog">
      <CardTypeModal :dialog="true" @select-type="handleCardTypeSelection" :product="product" @dialogValue="handleChangeDialog"/>
     </div>
    
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreditCardModal from "@/components/ui/CreditCardModal.vue";
import ErrorFormAlert from "@/components/ui/ErrorFormAlert";
import CardTypeModal from "@/components/ui/CardTypeModal.vue";

export default {
  name: "UserCreditCardWidget",
  components: {
    CreditCardModal, ErrorFormAlert, CardTypeModal
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    debt_fees: {
      type: Number,
      default: 0
    },
    haveDelinquentAccounts: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      debtReason: '',
      dialog: false,
      cardTypeDialog: false,
      errors: null,
    }
  },
  methods: {
    ...mapActions('parameters', ['getCreditCardReason']),
    ...mapActions('products', ['requestCreditCard',]),
    ...mapActions('users', ['updateMobileTC']),

    paymentClick () {
      window.open("https://qnt.com.co/pagos/", "_blank");
    },

    openCardTypeModal() {
      this.cardTypeDialog = true; // Abre el dialog de selección del tipo de tarjeta
      // console.log('Actions from products module:', this.product.user_credit_card_application,);
    },
    handleChangeDialog(value){
      this.cardTypeDialog = value
    },
    handleCardTypeSelection(type) {
      if (type === 'digital') {
        this.$router.push({ name: 'tc-request-digital' });
      } else if (type === 'physical') {
        this.$router.push({ name: 'tc-request' });
      }
    },
    rejectCreditCard() {
      this.$router.push({ name: 'tc-reject' });
    },
    async assignCreditCardReason(haveDelinquentAccounts, delinquentReason) {
      if (haveDelinquentAccounts || (delinquentReason && ["1E", "2D"].indexOf(delinquentReason) >= 0)) {
        await this.getCreditCardReason("1E");
        this.debtReason = this.creditCardDebtReason.description.replace("${debt_fees}", `$ ${this.debt_fees.toLocaleString()}`);
      } else if (delinquentReason) {
        await this.getCreditCardReason(delinquentReason);
        this.debtReason = this.creditCardDebtReason.description.replace("${debt_fees}", `$ ${this.debt_fees.toLocaleString()}`);
      }
    },
  },
  computed: {
    ...mapState({
      creditCardDebtReason: state => state.parameters.creditCardReason,
      defaultMobile: state => state.users.generalInformation.celular,
    }),
    isActive() {
      if (!this.product) {
        return false;
      }
      const state = this.product.has_digital_signature;
      if (state) {
        return false;
      } else {
        return true;
      }
    },
    hasSpecialDebt() {
      return this.product && this.product.delinquentReason && ["1E", "2D"].indexOf(this.product.delinquentReason) >= 0;
    },
    activePaymentButton() {
      if (!this.product) {
        return false;
      }
      return this.haveDelinquentAccounts || this.hasSpecialDebt;
    },
    quota() {
      return this.product.quota ? this.product.quota.toLocaleString() : '0';
    },

  },
  async created() {
    if (this.product) {
      await this.assignCreditCardReason(this.haveDelinquentAccounts, this.product.delinquentReason);
    }
  },
  watch: {
    async 'product'(newVal, oldVal) {
      if (newVal != oldVal) {
        await this.assignCreditCardReason(this.haveDelinquentAccounts, newVal.delinquentReason);
      }
    },
    async haveDelinquentAccounts(newVal) {
      if (this.product) {
        await this.assignCreditCardReason(newVal, this.product.delinquentReason);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.m-widget3 {
  .m-widget3__item {
    .m-widget3__header {
      display: flex;

      .m-widget3__info {
        align-self: center;

        .m-widget3__username {
          a {
            color: #575962;
            text-decoration: underline;
          }
        }
      }
    }

    .m-widget3__body {
      .m-widget3__text {
        color: #414141;
        font-size: 1.5rem;
      }
    }

    .m-widget3__user-img {
      align-self: auto;

      .m-widget3__img {
        width: 4.2rem;
        border-radius: 0px;
      }
    }
  }
}

.m-dropdown[m-dropdown-toggle="hover"] {
  padding: 8px 5px;

  a {
    text-decoration: none;
  }
}
</style>
