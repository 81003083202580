<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">CARTA DE ACEPTACIÓN PRODUCTO</span>
    </v-card-title>
    <v-card-text>
      <div class="text-justify mt-3">
        <p>Bogotá D.C., {{dateFormatted}}</p>
        <p>Señor(a):
          <br />
          {{name}}
        </p>
        <p class="mt-5">
          Tenemos el gusto de saludarlo, así como de informarle que para QNT S.A.S. es muy importante la claridad en la información a nuestros clientes. Por esta razón a continuación encontrará las condiciones bajo las cuales ha sido aprobada su tarjeta de crédito:
        </p>
        <ol>
          <li>Cupo Otorgado: ${{quota}}</li>
          <li>Características del producto
            <ul>
              <li>Diferido de compras de 1 a 36 meses</li>
              <li>Para uso de compras a nivel nacional únicamente</li>
              <li>Sin disponibilidad para realizar avances</li>
              <li>Cuota de administración mensual $18.750, (con o sin utilización).</li>
            </ul>
          </li>
          <li>Tasa de interés remuneratorio: Efectiva anual, expresada en su equivalente mensualvencido, y variable (vigente al momento de realizar su(s) compra(s)). Podrá consultarlas en nuestrapágina web www.qnt.com.co.</li>
          <li>Tasa de interés moratorio: La máxima legalmente permitida por la SuperintendenciaFinanciera de Colombia.</li>
          <li>Derechos del Deudor:
            <ul>
              <li>El cliente tendrá derecho a solicitar a QNT S.A.S, certificación acerca del estado de su obligacióny demás información que permitan la utilización e información del producto.</li>
              <li>Los demás que le otorgue la Ley, y el contrato de Productos de QNT S.A.S.</li>
            </ul>
          </li>
          <li>Derechos de Qnt:
            <ul>
              <li>En caso de incumplimiento en los pagos, QNT S.A.S realizará la gestión de cobro o contratará aterceros para realizarla, y podrá declarar vencido la totalidad del plazo y exigir el pago total delas obligaciones a cargo del cliente y a favor de QNT S.A.S; lo cual incluye capital adeudado,interés corriente y moratorio, gastos de cobranza, comisiones y otros cargos que deban sercargados al cliente de manera justificada.</li>
              <li>El pago de los intereses de mora no inhibe al QNT S.A.S para el ejercicio de acciones legales porlo cual podrá declarar vencidos los plazos y exigir la cancelación inmediata de lo adeudado porel cliente.</li>
              <li>Todos los demás que confiera la Ley así como los señalados en el contrato de productos deQNT S.A.S.</li>
            </ul>
          </li>
        </ol>
      </div>

      <div>
        <p class="mt-5 pt-5">
          <u>{{identification_number}}</u>
          <br />
          No. Documento
        </p>
      </div>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        block
        color="secondary"
        @click.stop.prevent="$emit('accept')"
      >
        Acepto términos legales
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>

export default {
  name: "DocumentAcceptedProduct",
  props: {
    identification_number: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    quota: {
      required: true,
      type: String
    },
    date: {
      default: () => (new Date()),
      type: Date
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
  },
  computed: {
    dateFormatted () {
      return this.formatDate(this.date.toLocaleString().substr(0, 10))
    }
  }

}
</script>
